<footer>
    <div class="container">
        <br>
        <div class="company-name-footer">
            <span>Boost</span><span class="text-color-primary">Aid</span>
        </div>
        <div class="footer-copyright">
            Copyright © 2024
        </div>
        <br>
        <div class="social-link-container">
            <a class="social-link" href="https://x.com/boostaid_btc" target="_blank">
                <img src="./images/icons/x-social-media-logo-icon.svg" width="40" height="40" alt="X">
            </a>
            <a class="social-link" mat-flat-button color="accent" href="https://docs.google.com/forms/d/e/1FAIpQLSd_HK_kAmzEURD9bdU1ZVu3NWcX-Cj2DnlH-3WT8VOApDEaCQ/viewform?usp=sf_link" target="_blank">Give
                Feedback</a>
        </div>

        <p class="feedback-text">
            Your insights matter to us! Share your feedback, suggestions, and thoughts. We'd love to hear from you.
        </p>

        <br><br>
        <div class="vertical-align">
            Developed by <a href="https://www.linkedin.com/company/pulseb/" target="_blank"><img
                    src="https://storage.googleapis.com/file.pulseb.com.br/assets/logo/v2/pulseb-2.png"
                    alt="Logo Pulse B."></a>
        </div>
    </div>
</footer>